import { useFormContext } from "react-hook-form";
import InputDiv from "./InputDiv";

const FormBox3 = () => {
    const methods = useFormContext();

    return <div className="flex flex-col gap-[15px] max-w-[350px] w-full px-4 lg:px-0 mx-auto lg:mx-0">
    <h2 className="text-[20px] text-[#000000] font-[700]">Doplňující informace</h2>
    <div className="flex flex-col gap-[8px]">
        <InputDiv type='date' text={"Termín"} name="termin" className='appearance-none bg-white' />
    </div>

    <div className="flex flex-col gap-[8px]">
        <label className="text-[16px] text-[#000000] font-[500]">Poznámka</label>
        <textarea rows={6} className="px-[8px] py-[16px] rounded-md resize-none" {...methods.register("description")}></textarea>
    </div>
</div>
}
export default FormBox3