/* eslint-disable jsx-a11y/iframe-has-title */
import Facebook2 from "../Images/Icons/facebook.png"
import Whatsapp from "../Images/Icons/whatsapp.png"

const Contact = () => {
    return <div id="kontakt">
        <div className="max-w-[1440px] mx-auto pt-6 lg:py-0 px-4">
            <h2 className="text-center text-[40px] md:text-[55px] font-[600] md:mt-[20px] header px-2 md:px-0">Kontakt</h2>
            <div className="max-w-[1202px] md:max-h-[529px] md:mb-[50px] flex flex-col md:flex-row md:justify-between mx-auto md:mt-[50px] gap-8">
                <div className="max-w-[320px] flex flex-col mx-auto md:mx-0 gap-6">
                    <div className="flex flex-col justify-center text-center gap-4">
                        <p className="font-bold">Ševčíkova 6, 130 00 Praha 3</p>
                        <p>IČO: 01448102</p>
                    </div>

                    <div className="flex flex-col justify-center text-center gap-5">
                        <p className="font-bold">David Havlík</p>
                        <p className="flex flex-row gap-2 items-center">
                            <a href="https://wa.me/+420724219287"><img src={Whatsapp} alt="whatsapp" className="max-h-8" /></a>
                            +420 724 219 287
                        </p>
                        <p><a href="mailto:david.havlik@prebersibyt.cz">david.havlik@prebersibyt.cz</a></p>
                        <p>IČO: 01448102</p>
                    </div>

                    <div className="flex flex-col justify-center text-center gap-5">
                        <p className="font-bold">Petr Kulhavý</p>
                        <p className="flex flex-row gap-2 items-center">
                            <a href="https://wa.me/+420737229559"><img src={Whatsapp} alt="whatsapp" className="max-h-8" /></a>
                            +420 737 229 559
                        </p>
                        <p><a href="mailto:petr.kulhavy@prebersibyt.cz">petr.kulhavy@prebersibyt.cz</a></p>
                        <p>IČO: 09752901</p>
                    </div>

                    <div className="flex flex-row gap-3 justify-center">
                        <a href="https://www.facebook.com/people/P%C5%99ebersibytcz/100066453351654/" target='_blank' 
                            rel="noreferrer"><img className="max-w-[40px] m-[8px]" src={Facebook2} alt="Přeber si byt"/>
                        </a>
                    </div>
                </div>

                <iframe
                    className="max-w-[calc(100vw_-_1rem)] md:max-w-[782px] w-full mt-6 lg:mt-0"
                    // width="782"
                    height="529"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.265265303318!2d14.445484776920168!3d50.08132021387279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b949b92f9fb41%3A0x383f2551183bbe66!2zxaBldsSNw61rb3ZhIDE0MjEvNiwgMTMwIDAwIFByYWhhIDMtxb1pxb5rb3Y!5e0!3m2!1scs!2scz!4v1720367128250!5m2!1scs!2scz"
                />
            </div>
        </div>
    </div>
}
export default Contact