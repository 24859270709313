const PriceListData = [{
    header: "Kontrola odstranění reklamovaných vad bytu",
    text: "Kontrola odstranění reklamovaných vad bytu",
    price: '2.500',
    id: 1,
},{
    header: "Kontrola odstranění reklamovaných vad domu",
    text: "Kontrola odstranění reklamovaných vad domu",
    price: '3.500',
    id: 2,
},{
    header: "Technický dozor",
    text: "Návštěva stavby",
    price: '2.500',
    id: 3,
},{
    header: "Společné prostory",
    text: "Společné prostory budou naceněny po seznámení se s podmínkami a rozsahem",
    id: 4,
}]
export default PriceListData