const ReferenceData = [{
    name: "Petra Smutná",
    text: "Vynaložené peníze se mi několikrát vrátily za opravené vady, na které bych hned tak nepřišla.",
},{
    name: "Alois Pekárek",
    text: "Flexibilní víkendová kontrola domu a vypracovaný protokol již druhý den dle domluvy.....doporučuji.",
},{
    name: "Aneta Černá",
    text: "Velmi přátelský a milý přístup, ale hlavně podpora a zastupování při výčtu vad a nedodělků.",
},{
    name: "Bořek Nový",
    text: "Získal jsem slevu na kupovaný dům na základě nalezených nedostatků z inspekce.",
},{
    name: "Anna Schwarzová",
    text: "Vzhledem k nižší ceně, než je u konkurence jsem se zpočátku obávala, ale služby byly perfektní.",
},{
    name: "SVJ Sazovická Zličín",
    text: "Práce při kontrole společných prostor před koncem záruky našeho bytového domu byla naprosto precizní, nalezeno přes 600 vad a úžasné know-how při řešení problémů s dodavatelem.",
},{
    name: "Alan Walker",
    text: "Great job in handing over my apartment. Keep it up.",
},]

export default ReferenceData