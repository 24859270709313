import InputDiv from "./InputDiv"

const FormBox1 = () => {
    return <div className="flex flex-col gap-[15px] max-w-[350px] w-full px-4 lg:px-0 mx-auto lg:mx-0">
    <h2 className="text-[20px] text-[#000000] font-[700]">Kontaktní údaje</h2>
    <InputDiv text={"Jméno"} name="firstName"/>
    <InputDiv text={"Příjmení"} name="lastName"/>
    <InputDiv text={"Telefon"} name="phone"/>
    <InputDiv text={"Email"} name="email"/>
</div>
}
export default FormBox1