import React, { useEffect, useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import { isUndefined } from 'lodash';
import { ImagesData } from '../Data/ImagesData';
import Captions from "yet-another-react-lightbox/plugins/captions";


const SimpleGallery = () => {
    const media = ImagesData;
    const [page, setPage] = useState(1);
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const pageSize = windowWidth >= 768
        ? 7
        : 5;
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    if (isUndefined(media)) {
        return <></>;
    }

    const showLoadMoreButton = media.length > page * pageSize;
    const slides = [];

    media.forEach((m) => {
        slides.push({
            src: m.path ?? '',
            alt: m.alt,
            width: m.width ?? 0,
            height: m.height ?? 0,
            autoPlay: true,
            description: m.alt,
        });
    });

    const getHorizontalSpan = (i) => {
        const index = i % pageSize;

        if (index === 0) {
            return 'col-span-2';
        }

        return 'col-span-1';
    };

    const getVerticalSpan = (i) => {
        const index = i % pageSize;

        if (index === 0 || index === 2) {
            return 'row-span-2 min-h-[228px] 4xl:max-h-[512px]';
        }

        if (index === 3) {
            return 'row-span-2 h-full 4xl:max-h-[549px]';
        }

        return 'row-span-1 min-h-[114px] max-h-[256px]';
    };

    return (
        <>
            <div className='grid grid-cols-2 px-4 grid-rows-[repeat(auto-fit,114px)] md:grid-cols-[repeat(auto-fill,minmax(342px,1fr))] md:grid-rows-[repeat(auto-fit,256px)] gap-[12px] xl:gap-[24px] grid-flow-dense'>
                {
                    media.slice(0, page * pageSize).map((file, index) => {
                        return (
                            <img
                                key={index}
                                src={file.path ?? '/images/project-box-placeholder.png'}
                                width={file.width ?? 200}
                                height={file.height ?? 300}
                                alt={file.alt}
                                className={`w-full rounded-[24px] 3xl:rounded-[40px] cursor-pointer h-full object-cover ${getHorizontalSpan(index)} ${getVerticalSpan(index)}`}
                                onClick={() => {
                                    setCurrentImage(file);
                                    setOpen(true);
                                }}
                            />
                        );
                    })
                }
            </div>

            <div className='flex flex-row justify-center'>
                {
                    showLoadMoreButton && (
                        <button
                        className='border-[2px] py-[16px] px-[24px] bg-[#47297B] text-[#FFFFFF] text-[18px] font-[700] rounded-md'
                            onClick={() => setPage((p) => p + 1)}
                            type="button"
                        >
                            Více
                        </button>
                    )
                }
            </div>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                plugins={[Thumbnails, Captions]}
                // @ts-ignore
                slides={slides}
            />
        </>
    );
};

export default SimpleGallery;