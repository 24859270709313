import TitlePage from "./Components/TitlePage";
import PropertyCheck from "./Components/PropertyCheck";
import PriceList from "./Components/PriceList";
import Reference from "./Components/Reference";
import Form from "./Components/Form";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";

function App() {
  return (
    <div>
      <TitlePage/>
      <PropertyCheck/>
      <PriceList/>
      <Reference/>
      <Form/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
