import Calculator from "../Compounds.js/Calculator"
import PriceListData from "../Data/PriceListData"
import PriceListBox from "../Compounds.js/PriceListBox"

const PriceList = () => {
    return <div className="bg-[#0000001A]" id="cenik">
        <div className="max-w-[1440px] mx-auto pb-[50px]">
            <h2 className="text-center text-[40px] md:text-[55px] font-[571] mb-6 lg:mb-[50px] pt-6 header px-2 md:px-0">Ceník</h2>
            <div className="flex justify-center">
                <Calculator/>
            </div>
            <div className="max-w-[1440px] flex flex-col justify-center items-center lg:grid lg:grid-cols-2 2xl:grid-cols-4 lg:px-4 mt-[50px] lg:items-stretch grow gap-[30px] mb-[50px] mx-4 lg:mx-auto">
                {
                    PriceListData.map((item, index) => <PriceListBox key={item.id} item={item} index={index}/>)
                }
            </div>
        </div>
    </div>
}
export default PriceList