const NavigationData = [{
        title: 'Služby',
        id: 1,
        href: 'sluzby',
    }, {
        title: 'Ceník',
        id: 2,
        href: 'cenik',
    }, {
        title: 'Reference',
        id: 3,
        href: 'reference',
    }, {
        title: 'Formulář',
        id: 4,
        href: 'formular',
    }, {
        title: 'Kontakt',
        id: 5,
        href: 'kontakt',
    }];
export default NavigationData;
