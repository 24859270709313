import Stairs from "../Images/Icons/ServicesIcons/Stairs.png"
import House from "../Images/Icons/ServicesIcons/House.png"
import Approval from "../Images/Icons/ServicesIcons/Approval.png"
import Window from "../Images/Icons/ServicesIcons/Window.png"
import Requirement from "../Images/Icons/ServicesIcons/Requirement.png"
import TechnicalSupport from "../Images/Icons/ServicesIcons/TechnicalSupport.png"
import FireExtengusier from "../Images/Icons/ServicesIcons/FireExtengusier.png"
import Roof from "../Images/Icons/ServicesIcons/Roof.png"

const ServicesData = [{
    id: 1, 
    image: Stairs, 
    text: 'Vizuální kontrola veškerých povrchů včetně přeměření rovinností povrchů, ploch a spádů',
},{
    id: 2, 
    image: House, 
    text: 'Kontrola výměry nemovitosti',
},{
    id: 3, 
    image: Approval, 
    text: 'Kontrola případných klientských změn',
},{
    id: 4, 
    image: Window, 
    text: 'Kontrola problémových detailů (parapet, uložení vany či sprchového koutu, atd.)',
},{
    id: 5, 
    image: Requirement, 
    text: 'Kontrola a přeměření hydroizolací, elektroinstalací, tepelných mostů a možnosti tvorby plísní ',
},{
    id: 6, 
    image: TechnicalSupport, 
    text: 'Kontrola technického zařízení budovy',
},{
    id: 7, 
    image: FireExtengusier, 
    text: 'Kontrola bezpečnostních, požárních a hygienických norem',
},{
    id: 8, 
    image: Roof, 
    text: 'Kontrola fasády, střechy a venkovních prostor',
}]

export default ServicesData