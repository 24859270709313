import { useState, useEffect } from "react"

const Calculator = () => {
    const [selected, setSelected] = useState("byt");
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (value < 0) {
          setValue(0);
        }
      }, [value]);

    return <div className="max-w-[431px] max-h-[385px] px-4 lg:px-0">
        <div className="max-w-[371px] max-h-[60px] p-[5px] bg-[#47297B80] rounded-3xl flex flex-row justify-between mx-auto cursor-pointer">
            <div
                className={`max-w-[180px] w-full max-h-[50px] px-[16px] py-[8px] text-center text-[20px] ${selected === "byt" ? "bg-[#FFFFFFB2] rounded-3xl" : ""}`}
                onClick={() => setSelected("byt")}
            >
                Byt
            </div>
            <div
                className={`max-w-[180px] w-full max-h-[50px] px-[16px] py-[8px] text-center text-[20px] ${selected === "dům" ? "bg-[#FFFFFFB2] rounded-3xl" : ""}`}
                onClick={() => setSelected("dům")}
            >
                Dům
            </div>
        </div>

        <div className="max-w-[371px] border-b border-[#CBD2E0] mx-auto">
            <div className="max-w-[371px] max-h-[148px] mx-auto mt-[30px]">
                <div>
                    <p className="text-center text-[18px] font-[400]">Užitná plocha</p>
                    <p className="text-center text-[14px] font-[300]">Podlahová plocha vč. výměry sklepa, balkónu, terasy, garážového stání atd.</p>
                </div>

                {/*<input type="number" value={value} placeholder="m2" className="w-full h-[69px] text-center mt-[10px] rounded-lg" onChange={(e) => setValue(e.target.value)}/>*/}
                <div className="py-2 px-3 bg-gray-100 rounded-lg h-fit mt-6" data-hs-input-number="">
                    <span className="block text-xs text-gray-500 dark:text-neutral-400 text-center">
                        Uveďte rozměr v m2
                    </span>
                    <div className="w-full flex justify-between items-center gap-x-5">

                        <button type="button" className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" tabindex="-1" aria-label="Decrease" data-hs-input-number-decrement="" 
                            onClick={() => setValue((value) => value - 1)}>
                            <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M5 12h14"></path>
                            </svg>
                        </button>
                        <div className="grow">
                            <input className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none text-center" /*style="-moz-appearance: textfield;"*/ type="number" aria-roledescription="Number field" value={value} data-hs-input-number-input="" onChange={(e) => setValue(e.target.value)}/>
                        </div>
                        <div className="flex justify-end items-center gap-x-1.5">
                            
                            <button type="button" className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" tabindex="-1" aria-label="Increase" data-hs-input-number-increment=""
                                onClick={() => setValue((value) => value + 1)}>
                                <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M5 12h14"></path>
                                <path d="M12 5v14"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-[292px] max-h-[86px] mx-auto mt-[30px] mb-[30px]">
                {(selected === "byt" && value <= 40) && <p className="text-[48px] text-[#47297B] text-center font-[683] header">3.500 Kč</p>}
                {(selected === "byt" && value > 40) && value <= 80 && <p className="text-[48px] text-[#47297B] text-center font-[683] header">4.000 Kč</p>}
                {(selected === "byt" && value > 80) && value <= 120 && <p className="text-[48px] text-[#47297B] text-center font-[683] header">4.500 Kč</p>}
                {(selected === "byt" && value > 120) && <p className="text-[48px] text-[#47297B] text-center font-[683] header">5.000 Kč</p>}
                {(selected === "dům" && value <= 100) && <p className="text-[48px] text-[#47297B] text-center font-[683] header">6.000 Kč</p>}
                {(selected === "dům" && value > 100) && value <= 150 && <p className="text-[48px] text-[#47297B] text-center font-[683] header">6.500 Kč</p>}
                {(selected === "dům" && value > 150) && value <= 200 && <p className="text-[48px] text-[#47297B] text-center font-[683] header">7.000 Kč</p>}
                {(selected === "dům" && value > 200) && <p className="text-[48px] text-[#47297B] text-center font-[683] header">7.500 Kč</p>}
                <p className="text-[12px] text-center">Doprava mimo Prahu 8Kč/km (počítáno ze Žižkova)</p>
            </div>
        </div>    
    </div>
}
export default Calculator