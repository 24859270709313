import ServiceData from "../Data/ServicesData"
import ServiceBox from "../Compounds.js/ServiceBox"

const PropertyCheck = () => {
    return <div className="lg:max-w-[1440px] mx-auto max-w-[100vw]" id="sluzby">  
        <div className="max-w-[1220px] max-h-fit mt-[50px] mx-auto lg:pb-[66px]">
            <h2 className="text-center text-[40px] md:text-[55px] font-[600] header px-2 md:px-0">Kontroly nemovitostí</h2>
            <div className="max-w-[1220px] lg:max-h-[454px] mt-[50px] grid grid-cols-1 lg:grid-cols-4 gap-10 lg:gap-[20px]">
                {
                    ServiceData.map((item) => <ServiceBox key={item.id} item={item}/>)
                }
            </div>

            <div className="mt-[50px] flex flex-col gap-[20px]">
                <h2  className="text-center text-[40px] md:text-[55px] font-[600] header px-2 md:px-0">Technický dozor</h2>
                <p className="text-center text-[18px] px-6 lg:px-0">Autorizovaný dozor, kontrola stavební firmy při realizaci zakázky, zastupování a jednání za klienta.</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px] pb-[50px]">
                    <div className="border rounded-lg px-[24px] py-[20px] mx-4 lg:mx-0">
                        <p className="py-[8px] border-b">Hlídání technologických postupů</p>
                        <p className="py-[8px] border-b">Hlídání harmonogramu</p>
                        <p className="py-[8px] border-b">Přejímání dílčích prací</p>
                        <p className="py-[8px] border-b">Dohled nad čerpáním financí</p>
                        <p className="py-[8px] border-b">Kontrola jednotlivých položek</p>
                        <p className="py-[8px] border-b">Kontrola případných klientských změn</p>
                        <p className="py-[8px] border-b">Kontrola technického zařízení budovy</p>
                        <p className="py-[8px]">Kontrola hydroizolací</p>
                    </div>
                    <div className="border rounded-lg px-[24px] py-[20px] flex flex-col justify-center mx-4 lg:mx-0">
                        <p className="py-[8px] border-b">Prověření bezpečnostních, požárních a hygienických norem</p>
                        <p className="py-[8px] border-b">Přeměření rovinností povrchů a ploch, spádů a úhlů</p>
                        <p className="py-[8px] border-b">Přeměření napětí elektroinstalací</p>
                        <p className="py-[8px] border-b">Měření vlhkosti</p>
                        <p className="py-[8px] border-b">Vizuální kontrola veškerých povrchů (hledání dutých obkladů a dlažeb, prasklin, retuší, škrábanců, vrypů,kazů, různých odstínů)</p>
                        <p className="py-[8px]">Kontrola problémových detailů (parapet, instalační šachta, uložení vany a sprchového koutu, atd.)</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default PropertyCheck