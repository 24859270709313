import Logo from "../Images/NavigationLogo.png"

const AboutUs = () => {
    return <div className='pt-[50px] lg:pt-[100px] lg:min-h-[800px] flex flex-col items-center justify-center'>
        <img src={Logo} alt='Přeber si byt' className='w-[300px] sm:mt-[200px] lg:max-w-[400px] lg:mt-[100px]' />
        <h1 className='text-center text-[#F8D404] text-[40px] lg:text-[96px] font-[600] mt-[10px] px-6 lg:px-0 header'>Přeber si byt</h1>
        <h2 className="text-center text-white text-[25px] md:text-[32px] font-[600] px-6 lg:px-0">Kontrola nemovitosti s precizností</h2>
        <p className="mt-[32px] text-white text-[16px] font-[500] px-6 lg:px-0 pb-6 sm:pb-0">
            Zkušenosti z více než 4000 nemovitostí a z reklamačního oddělení velkého developera a nadnárodní stavební firmy
        </p>
    </div>;
};
export default AboutUs;
