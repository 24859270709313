import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const InputDiv = ({text, name, type = 'text', className = ''}) => {
    const methods = useFormContext();
    const formState = methods.formState;

    return <div className="flex flex-col gap-[8px]">
        <label className="text-[16px] text-[#000000] font-[500]">{text}<span className="text-red-600">*</span></label>
        <input
            type={type}
            min={type === 'date' ? new Date().toISOString().split('T')[0] : undefined}
            className={`px-[8px] py-[16px] max-h-[40px] rounded-md ${className}`}
            {...methods.register(name)}
        />
        <ErrorMessage errors={formState.errors} name={name} render={({ message }) => <p className='text-red-500'>{message}</p>} />
</div>
}
export default InputDiv