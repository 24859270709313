import { useState } from "react"
import star from "../Images/Icons/star.png"
import star2 from "../Images/Icons/star2.png"
import user from "../Images/Icons/user.png"

const ReferenceBox = ({data}) => {
    const [selected, setSelected] = useState(false);
    const handleSelect = (actualSelected) => {
        setSelected((actualSelected) => !actualSelected)
    }
    return <div className="flex flex-col border gap-[8px] px-[20px] py-[10px] rounded-lg border-[#4A5468] cursor-pointer" onClick={() => handleSelect(selected)}>
        <div className="flex flex-row gap-[8px]">
            <img className="max-h-[30px] my-auto" src={user} alt={data.name} />
            <p className="text-[16px] text-[#2D3648] font-[700] my-auto">{data.name}</p>
        </div>
        {!selected && <p className="text-[14px] text-[#717D96] font-[400] line-clamp-2">{data.text}</p>}
        {selected && <p className="text-[14px] text-[#717D96] font-[400]">{data.text}</p>}

        <div className="flex flex-row gap-[4px] mt-auto">
            <img className="max-h-[15px] my-auto" src={star2} alt=""/>
            <img className="max-h-[15px] my-auto" src={star2} alt=""/>
            <img className="max-h-[15px] my-auto" src={star2} alt=""/>
            <img className="max-h-[15px] my-auto" src={star2} alt=""/>
            <img className="max-h-[15px] my-auto" src={star2} alt=""/>
        </div>
    </div>
}
export default ReferenceBox