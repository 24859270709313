import image1 from "../Images/ReferencesImages/image1.jpg"
import image2 from "../Images/ReferencesImages/image2.jpg"
import image3 from "../Images/ReferencesImages/image3.jpg"
import image4 from "../Images/ReferencesImages/image4.jpg"
import image5 from "../Images/ReferencesImages/image5.jpg"
import image6 from "../Images/ReferencesImages/image6.jpg"
import image7 from "../Images/ReferencesImages/image7.jpg"
import image8 from "../Images/ReferencesImages/image8.jpg"
import image9 from "../Images/ReferencesImages/image9.jpg"
import image10 from "../Images/ReferencesImages/image10.jpg"
import image11 from "../Images/ReferencesImages/image11.jpg"
import image12 from "../Images/ReferencesImages/image12.jpg"
import image13 from "../Images/ReferencesImages/image13.jpg"
import image14 from "../Images/ReferencesImages/image14.jpg"
import image15 from "../Images/ReferencesImages/image15.jpg"
import image16 from "../Images/ReferencesImages/image16.jpg"
import image17 from "../Images/ReferencesImages/image17.jpg"
import image18 from "../Images/ReferencesImages/image18.jpg"
import image19 from "../Images/ReferencesImages/image19.jpg"
import image20 from "../Images/ReferencesImages/image20.jpg"
import image21 from "../Images/ReferencesImages/image21.jpg"
import image22 from "../Images/ReferencesImages/image22.jpg"
import image23 from "../Images/ReferencesImages/image23.jpg"
import image24 from "../Images/ReferencesImages/image24.jpg"
import image25 from "../Images/ReferencesImages/image25.jpg"
import image26 from "../Images/ReferencesImages/image26.jpg"
import image27 from "../Images/ReferencesImages/image27.jpg"
import image28 from "../Images/ReferencesImages/image28.jpg"
import image29 from "../Images/ReferencesImages/image29.jpg"


export const ImagesData = [
    {
        path: image1,
        alt: 'Central Park - Pitterova, Žižkov',
        width: 800,
        height: 600,
    },
    {
        path: image2,
        alt: 'Královny Žofie - Kunratice',
        width: 800,
        height: 600,
    },
    {
        path: image3,
        alt: 'Ecocity Malešice',
        width: 800,
        height: 600,
    },
    {
        path: image4,
        alt: 'Nad Přehradou II - Horní Měcholupy',
        width: 800,
        height: 600,
    },
    {
        path: image5,
        alt: 'Chýně',
        width: 800,
        height: 600,
    },
    {
        path: image6,
        alt: 'Hugo Haase - Kaskády IV - Hlubočepy',
        width: 800,
        height: 600,
    },
    {
        path: image7,
        alt: 'Kloboučnická - Liberty Building - Nusle',
        width: 800,
        height: 600,
    },
    {
        path: image8,
        alt: 'Dářská - Kyje',
        width: 800,
        height: 600,
    },
    {
        path: image9,
        alt: 'Mníšek pod Brdy',
        width: 800,
        height: 600,
    },
    {
        path: image10,
        alt: 'Na Fialce - Říčany',
        width: 800,
        height: 600,
    },{
        path: image11,
        alt: 'Na Líše - Michle',
        width: 800,
        height: 600,
    },{
        path: image12,
        alt: 'Nepomuckých - Letňany',
        width: 800,
        height: 600,
    },{
        path: image13,
        alt: 'Nové Měcholupy IV',
        width: 800,
        height: 600,
    },{
        path: image14,
        alt: 'Velká skála',
        width: 800,
        height: 600,
    },{
        path: image15,
        alt: 'Viladomy Zbuzany',
        width: 800,
        height: 600,
    },{
        path: image16,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image17,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image18,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image19,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image20,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image21,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image22,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image23,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image24,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image25,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image26,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image27,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image28,
        alt: '',
        width: 800,
        height: 600,
    },{
        path: image29,
        alt: '',
        width: 800,
        height: 600,
    },
];