import { useEffect, useState } from 'react';
import NavigationData from '../Data/NavigationData';
import Logo from "../Images/NavigationLogo.png";
import classNames from 'classnames';
import NavigationLogo from "../Images/NavLogo.png"

const Navigation = () => {
    const [showBackground, setShowBackground] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // const hero = document.querySelector('#hero');

            setShowBackground(window.scrollY > 50); // (hero.clientHeight - 125));
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth < 1024) {
                setShowMobileMenu(true);
            } else {
                setShowMobileMenu(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [isMobileMenuOpen]);

    if (showMobileMenu) {
        return <div>
            <div className='flex flex-row justify-between mx-4'>
                <img className='h-8 my-2' src={NavigationLogo} alt='Přeber si byt'/>

                <div className='my-auto'>
                    <div
                        className="space-y-2 cursor-pointer"
                        onClick={() => setIsMobileMenuOpen((prev) => !prev)}
                    >
                        <span className="block h-0.5 w-8 bg-[#f8d404]"></span>
                        <span className="block h-0.5 w-8 bg-[#f8d404]"></span>
                        <span className="block h-0.5 w-8 bg-[#f8d404]"></span>
                    </div>
                </div>
            </div>

            <div className={classNames('fixed bg-gray-900/90 h-screen !z-[200000] transition-all duration-1000 top-0', {
                'right-0 w-[100%]': isMobileMenuOpen,
                '-right-[100%] w-[90%]': !isMobileMenuOpen,
            })}>
                <div className='flex flex-row justify-end'>
                    <div
                        className="space-y-2 cursor-pointer mx-6 my-6 text-[#f8d404] hover:text-[#dfbe03]"
                        onClick={() => setIsMobileMenuOpen(false)}
                    >
                        <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </div>
                </div>
                <div className='flex flex-col gap-4 px-[10%] py-6'>
                    {
                        NavigationData.map((NavData) => <a 
                                onClick={(event) => {
                                    event.preventDefault();

                                    const targetElement = document.getElementById(NavData.href);

                                    if (targetElement !== null) {
                                        const targetElementPositionY = targetElement.getBoundingClientRect().y;
                                        const offset = document.getElementById('header')?.offsetHeight;
                                        const windowScrollLocation = window.scrollY;
                                        const finalSrollPosition = targetElementPositionY + windowScrollLocation - (offset ?? 0);

                                        window.scrollTo(0, finalSrollPosition);
                                    }

                                    setIsMobileMenuOpen(false)
                                }}
                                href={NavData.href}
                                key={NavData.id}
                                className='text-white hover:text-slate-200 w-fit'
                            >
                                {NavData.title}
                            </a>
                        )
                    }
                </div>
            </div>
        </div>
    }

    return <div id='header' className={`flex flex-row lg:justify-between lg:items-center lg:mx-auto lg:h-[70px] lg:px-[150px] text-lg font-semibold fixed !z-[20000] lg:w-full ${showBackground ? 'bg-[#2f2e2e] py-4' : 'bg-transparent py-4 lg:py-'}`}>
        <a href='#hero' id='hero'>
            <img className={`h-[47px] mx-auto py-[5px] lg:mx-0 hidden lg:block`} src={NavigationLogo} alt='Přeber si byt'/>
        </a>
        {
            showBackground === false && <div></div>
        }
        <div className='flex mx-auto lg:mx-0 lg:gap-[50px]'>
            {
                NavigationData.map((NavData) => <a 
                    onClick={(event) => {
                        event.preventDefault();

                        const targetElement = document.getElementById(NavData.href);

                        if (targetElement !== null) {
                            const targetElementPositionY = targetElement.getBoundingClientRect().y;
                            const offset = document.getElementById('header')?.offsetHeight;
                            const windowScrollLocation = window.scrollY;
                            const finalSrollPosition = targetElementPositionY + windowScrollLocation - (offset ?? 0);

                            window.scrollTo(0, finalSrollPosition);
                        }
                    }}
                    href={NavData.href}
                    className={`text-[#fff] decoration-[#f8d404] hover:underline underline-offset-8 decoration-2 ${showBackground ? 'text-[#fff]' : 'text-white'}`}
                    key={NavData.id}>
                        {NavData.title}
                    </a>
                )
            }
        </div>
    </div>;
};
export default Navigation;
