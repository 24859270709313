import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import ReferenceBox from "../Compounds.js/ReferenceBox";
import { useEffect, useState, useRef } from "react";
import ReferenceData from "../Data/ReferenceData";
import SimpleGallery from '../Compounds.js/SimpleGallery';

const Reference = () => {
    const [slidesPerView, setslidesPerView] = useState(3);
    const referenceSwiperRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth < 768) {
                setslidesPerView(1);
            } else if (screenWidth < 1024 && screenWidth >= 768 ) {
                setslidesPerView(3);
            } else if (screenWidth < 1680 && screenWidth >= 1024 ) {
                setslidesPerView(4);
            } else if (screenWidth >= 1680) {
                setslidesPerView(5);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <div className="max-w-[1440px] mx-auto pt-6" id="reference">
        <div>
            <h2 className="text-center text-[40px] md:text-[55px] font-[600] header px-2 md:px-0">Reference</h2>
            <div className="mt-[50px] flex flex-col gap-[33px] pb-[50px] px-4">
            <SimpleGallery/>
                <div>
                    <div className="flex flex-row">
                        <div className="">
                            <button
                                className="flex flex-col justify-center items-center h-full"
                                onClick={() => referenceSwiperRef.current.swiper.slidePrev()}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                </svg>
                            </button>
                        </div>
                        <Swiper
                            spaceBetween={18}
                            slidesPerView={slidesPerView}
                            ref={referenceSwiperRef}
                        >
                            {
                                ReferenceData.map((data, index) => <SwiperSlide key={index}><ReferenceBox data={data}/></SwiperSlide>)
                            }
                        </Swiper>
                        <div className="">
                            <button
                                className="flex flex-col justify-center items-center h-full"
                                onClick={() => referenceSwiperRef.current.swiper.slideNext()}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}
export default Reference