import Logo from "../Images/NavLogo.png"

const Footer = () => {
    return <div className="bg-[#47297B] min-h-[211px] flex flex-row justify-between py-5 md:py-0 px-2 lg:px-0">
        <div className="flex flex-row justify-center md:grid-cols-3 max-w-[1440px] mx-auto md:px-10 min-h-full w-full  gap-8">
            <div>
                <h2 className="max-w-[193px] text-[24px] text-[#FFFFFF] font-[571] mt-[42px]">Přeber si byt</h2>
                { /* <div className="max-w-[88px] md:max-h-[40px] flex justify-between mt-2 md:mt-[38px]">
                <img className="max-h-[24px] m-[8px]" src={Facebook} alt="" />
                    <img className="max-h-[24px] m-[8px]" src={Whatsapp} alt="" />
                    <img className="max-h-[24px] m-[8px]" src={Instagram} alt="" /> 
                </div>*/ }
            </div>
            <div className="md:max-w-[376px] md:max-h-[126px] flex flex-col my-auto sm:mx-auto">
                <h2 className="text-[20px] text-[#F5BD1F] font-[483]">Kontakt</h2>
                <div className="mt-[24px]"><span className="text-[16px] text-[#FFFFFF] font-[500]">IČO: </span><span className="text-[16px] text-[#FFFFFF] font-[400]">01448102</span></div>
                <div className="mt-[24px]"><span className="text-[16px] text-[#FFFFFF] font-[500]">Tel.: </span><span className="text-[16px] text-[#FFFFFF] font-[400]">+420 724 219 287</span></div>
            </div>
            <div className="hidden md:flex flex-col md:justify-center my-auto">
                <img className="md:max-h-[110px] md:ml-auto" src={Logo} alt="" />
            </div>
        </div>
    </div>
}
export default Footer