import { useState, createRef } from "react"
import FormBox1 from "../Compounds.js/Form/FormBox1";
import FormBox2 from "../Compounds.js/Form/FromBox2";
import FormBox3 from "../Compounds.js/Form/FormBox3";
import Button from "../Compounds.js/Button";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { ErrorMessage } from "@hookform/error-message";

// SiteKey: 6Ld5sg8qAAAAAC4Yd-NmdyH564cPaIKDrkbM0vTe
// Secret: 6Ld5sg8qAAAAANLQxr_G46skAtOgNWHvlVlvj06p

const schema = yup
    .object({
        firstName: yup.string().required('Jméno je povinné'),
        lastName: yup.string().required('Přijmení je povinné'),
        phone: yup.string().required('Telefon je povinný'),
        email: yup.string().email('E-mail musí být ve správném formátu').required('E-mail je povinný'),
        address: yup.string().required('Adresa je povinná'),
        houseDescription: yup.string().required('Výměra je povinná'),
        termin: yup.string().required('Termín je povinný'),
        terms: yup.boolean().oneOf([true], 'Musíte souhlasit'),
    })
    .required()

const Form = () => {
    const refCaptcha = createRef();
    const [status, setStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState("Byt v záruce - novostavba")
    const methods = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = (data) => {
        const token = refCaptcha.current.getValue();
        setIsLoading(true);

        if (token === '' || token === undefined || token === null) {
            alert('Potvrďte, že nejste robot.');
            setIsLoading(false);

            return;
        }

        const formData = {
            ...data,
            type: selected,
            "g-recaptcha-response": token,
        };


        emailjs.send('service_bpwxh52', 'template_t7dlt6p', formData, {
            publicKey: 'yRdGdmSNlKBLQ9i3L',
            
        })
        .then(
            () => {
                alert('V nejbližší možné době Vás budeme kontaktovat.');
                methods.reset();
                window.grecaptcha.reset();
                setIsLoading(false);
            },
            (error) => {
                alert('Formulář se nepodařilo odeslat, prosím kontaktujte nás telefonicky nebo e-mailem.');
                methods.reset();
                window.grecaptcha.reset();
                setIsLoading(false);
            },
        );
    }

    return <div className="bg-[#0000001A]" id="formular">
        <div className="max-w-[1440px] mx-auto pb-[50px] pt-6 px-6 xl:px-0">
            <h2 className="text-center text-[40px] md:text-[55px] font-[600] header px-2 md:px-0">
                Formulář
            </h2>
            <div className="max-w-[1200px] mx-auto md:mt-[50px]">
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="border-b border-[#CBD2E0] pb-[50px] mx-4 lg:mx-0">
                        <div className="flex flex-col gap-4 w-fit mx-auto lg:w-auto lg:mx-0 mt-4 lg:mt-0">
                            <h2 className="text-[20px] font-[700]">Vyberte službu:</h2>
                            <div className="grid grid-cols-2 lg:flex lg:flex-row lg:justify-between p-[5px] bg-[#47297B80] rounded-3xl gap-[8px] cursor-pointer">
                                <p
                                    className={`text-center text-[16px] font-[500] p-[12px] ${selected === "Byt v záruce - novostavba" ? "text-[#2D3648] bg-[#FFFFFFB2] rounded-3xl" : "text-[#FFFFFF]"} `}
                                    onClick={() => setSelected("Byt v záruce - novostavba")}
                                >
                                    Byt v záruce - novostavba
                                </p>
                                <p
                                    className={`text-center text-[16px] font-[500] p-[12px] ${selected === "Dům v záruce - novostavba" ? "text-[#2D3648] bg-[#FFFFFFB2] rounded-3xl" : "text-[#FFFFFF]"} `}
                                    onClick={() => setSelected("Dům v záruce - novostavba")}
                                >
                                    Dům v záruce - novostavba
                                </p>
                                <p
                                    className={`text-center text-[16px] font-[500] p-[12px] ${selected === "Starší byt" ? "text-[#2D3648] bg-[#FFFFFFB2] rounded-3xl" : "text-[#FFFFFF]"} `}
                                    onClick={() => setSelected("Starší byt")}
                                >
                                    Starší byt
                                </p>
                                <p
                                    className={`text-center text-[16px] font-[500] p-[12px] ${selected === "Starší dům" ? "text-[#2D3648] bg-[#FFFFFFB2] rounded-3xl" : "text-[#FFFFFF]"} `}
                                    onClick={() => setSelected("Starší dům")}
                                >
                                    Starší dům
                                </p>
                                <p
                                    className={`text-center text-[16px] font-[500] p-[12px] ${selected === "Společné prostory bytového domu" ? "text-[#2D3648] bg-[#FFFFFFB2] rounded-3xl" : "text-[#FFFFFF]"} `}
                                    onClick={() => setSelected("Společné prostory bytového domu")}
                                >
                                    Společné prostory bytového domu
                                </p>
                                <p
                                    className={`flex justify-center items-center lg:block text-center text-[16px] font-[500] p-[12px] ${selected === "Technický dozor" ? "text-[#2D3648] bg-[#FFFFFFB2] rounded-3xl" : "text-[#FFFFFF]"} `}
                                    onClick={() => setSelected("Technický dozor")}
                                >
                                    Technický dozor
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col lg:flex-row justify-between mt-[50px] gap-8">
                        <FormBox1 />
                        <FormBox2 />
                        <FormBox3 />
                    </div>
                    <div className="flex flex-col gap-2 mt-6 justify-center items-center">
                        <div className="flex flex-row gap-2 justify-center">
                            <input id="terms-checkbox" type="checkbox" {...methods.register('terms')} />
                            <label for='terms-checkbox'>Souhlasím se zpracováním osobních údajů.<span className="text-red-600">*</span></label>
                        </div>
                        <ErrorMessage errors={methods.formState.errors} name='terms' render={({ message }) => <p className='text-red-500'>{message}</p>} />
                    </div>

                    <div className="mt-[50px] flex justify-center">
                        <div className="flex flex-col gap-4">
                            <ReCAPTCHA
                                sitekey='6LeCtg8qAAAAADUGd3sjdYiVQYXemz6e_PflqqfV'
                                ref={refCaptcha}
                                onChange={() => setStatus(null)}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center mt-[50px]">
                        <Button text={"Odeslat"} isLoading={isLoading} />
                    </div>
                </form>
                </FormProvider> 
            </div>
        </div> 
    </div>
}
export default Form