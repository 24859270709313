import Navigation from "../Compounds.js/Navigation";
import AboutUs from "../Compounds.js/AboutUs";
import frontPageImage from "../Images/title1.jpg";

const TitlePage = () => {
    return <div className='min-h-[100vh]'>
    <div className='relative bg-cover min-h-screen bg-[#000000]/60 bg-blend-overlay' style={{ backgroundImage: `url("${frontPageImage}")` }} id='hero'>
        <div className=''>
            <Navigation/>
            <AboutUs/>
        </div>
    </div>

</div>;
}
export default TitlePage