const PriceListBox = ({item, index}) => {
    const isLeft = index === 0 || index === 2;
    return <div className={`bg-[#FFFFFF80] max-w-[380px] lg:max-h-[380px] w-full h-auto flex flex-col min-h-full py-[32px] px-[16px] 
                            border border-[#E0E0E0] rounded-lg shadow-lg shadow-[#00000040] ${isLeft ? "lg:ml-auto 2xl:ml-0" : ""}`}>
        <div className="mx-auto grid grid-rows-3 gap-[30px] h-full">
            <p className="text-[24px] text-center font-[700]">{item.header}</p>
            <p className="text-[18px] text-center font-[400]">{item.text}</p>
            { 
                item.price && <p className="text-[36px] text-center text-[#47297B] font-[400] header">{item.price} Kč</p>
            }
        </div>
    </div>
}
export default PriceListBox