import InputDiv from "./InputDiv";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const FormBox2 = () => {
    const methods = useFormContext();
    const formState = methods.formState;

    return <div className="flex flex-col gap-[15px] max-w-[350px] w-full px-4 lg:px-0 mx-auto lg:mx-0">
        <h2 className="text-[20px] text-[#000000] font-[700]">Informace o domu/bytu</h2>
        <div className="flex flex-col gap-[8px]">
            <label className="text-[16px] text-[#000000] font-[500]">Adresa domu/bytu<span className="text-red-600">*</span></label>
            <textarea rows={3} className="px-[8px] py-[16px] rounded-md resize-none" {...methods.register("address")}></textarea>
            <ErrorMessage errors={formState.errors} name='address' render={({ message }) => <p className='text-red-500'>{message}</p>} />
        </div>
        <InputDiv text={"Výměra domu/bytu (v m2)"} name="houseDescription"/>
    </div>
}
export default FormBox2